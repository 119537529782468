




































import { Component, Vue } from "vue-property-decorator";
import { UserCredentials } from "@/store/modules/auth/types";
import AuthModule from "@/store/modules/auth";
import AlertModule, { AlertType } from "@/store/modules/alert";
import PasswordTextField from "@/components/text-fields/password/PasswordTextField.vue";

/**
 * Login view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        PasswordTextField,
    },
})
export default class Login extends Vue {
    // entered user credentials
    public userCredentials: UserCredentials = {
        email: "",
        password: "",
    };

    /**
     * Calls AuthModule.login and displays an alert if an error occurs
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    public login() {
        AuthModule.login(this.userCredentials).catch((err) => {
            const errorMessage = this.$t(
                "alerts.error." + err.message
            ).toString();
            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        });
    }
}
